export enum PopupOverlay {
    None,
    Manifesto,
    Fabula = 2,
    AndroidInfo,
    ModuleInfo,
    FAQ,
    Terms,
    Privacy,
    Story,
}
