import { CURRENT_ACCENT } from '../../../graphics/helpers/LandingTheme';

export function VectorLookup() {
    return (
        <svg style={{ display: 'none' }}>
            <defs>
                <linearGradient id="paint0_linear_1591_39358" x1="363.611" y1="21.4998" x2="2.0876e-05" y2="21.4999" gradientUnits="userSpaceOnUse">
                    <stop stopColor={CURRENT_ACCENT()} />
                    <stop offset="1" stopColor="#373744" />
                </linearGradient>
                <linearGradient id="paint1_linear_1591_39358" x1="436.389" y1="21.4998" x2="800" y2="21.4999" gradientUnits="userSpaceOnUse">
                    <stop stopColor={CURRENT_ACCENT()} />
                    <stop offset="1" stopColor="#373744" />
                </linearGradient>
            </defs>

            {/* 800x22 */}
            {' '}
            <g id="svg-manifesto-lines">
                <path d="M232.236 7.02787L231.789 6.80426L232.236 7.02787ZM227.764 15.9721L228.211 16.1957L227.764 15.9721ZM0 21H218.82V22H0V21ZM227.317 15.7485L231.789 6.80426L232.683 7.25148L228.211 16.1957L227.317 15.7485ZM241.18 1H374V2H241.18V1ZM231.789 6.80426C233.567 3.24703 237.203 1 241.18 1V2C237.582 2 234.292 4.03303 232.683 7.25148L231.789 6.80426ZM218.82 21C222.418 21 225.707 18.967 227.317 15.7485L228.211 16.1957C226.433 19.753 222.797 22 218.82 22V21Z" fill="url(#paint0_linear_1591_39358)" />
                <path d="M567.764 7.02787L568.211 6.80426L567.764 7.02787ZM572.236 15.9721L571.789 16.1957L572.236 15.9721ZM800 21H581.18V22H800V21ZM572.683 15.7485L568.211 6.80426L567.317 7.25148L571.789 16.1957L572.683 15.7485ZM558.82 1H426V2H558.82V1ZM568.211 6.80426C566.433 3.24703 562.797 1 558.82 1V2C562.418 2 565.708 4.03303 567.317 7.25148L568.211 6.80426ZM581.18 21C577.582 21 574.293 18.967 572.683 15.7485L571.789 16.1957C573.567 19.753 577.203 22 581.18 22V21Z" fill="url(#paint1_linear_1591_39358)" />
                <rect width="3" height="3" rx="1.5" transform="matrix(-1 0 0 1 397 0)" fill="white" />
                <rect width="3" height="3" rx="1.5" transform="matrix(-1 0 0 1 406 0)" fill="white" />
            </g>

            {/* 22x22 */}
            <g id="editor-info-icon" fill="none">
                <path d="M21 11c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C1 5.4771 5.4771 1 11 1c5.5228 0 10 4.4771 10 10Z" fill="#07070C" stroke={CURRENT_ACCENT()} />
                <path d="M12.0245 9.8121H9.2353c-.2846 0-.3416.5278 0 .5865.3415.0586.6496.0092.7969.3519 0 0-.5123 2.6978-.5692 4.2226-.057 1.5249 2.903 1.1143 3.4722.2933 0 0 .2277-.6452-.1707-.4692-.3985.1759-.8539.3519-1.0816.1759-.2277-.1759.1218-2.0534.5123-3.9294.0717-.6284.1632-1.173-.1707-1.2316ZM12.3015 7.2903c0 .7125-.5607 1.2902-1.2523 1.2902s-1.2523-.5777-1.2523-1.2903C9.797 6.5777 10.3576 6 11.0492 6s1.2523.5777 1.2523 1.2903Z" fill={CURRENT_ACCENT()} />
            </g>
        </svg>
    );
}
