import { useEffect, useRef } from 'react';

export function useInterval(callback : () => void, delay: number) {
    const savedCallback = useRef(() => {
        console.log(123);
    });

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
